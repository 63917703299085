import React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";

const IndexPage = ({data}) => {
    let dataProtectionPageData = data.allStrapiPageDatenschutzs.edges[0].node;
    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="datenschutz">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Datenschutz | Paixon</title>
                <meta name="description"
                      content="Das Datenschutz von Paixon, den Schweizer Voice- und Chatbot Experten. Stöbern Sie auf unserer Webseite und erfahren Sie mehr zu virtuellen Assistenten."/>
            </Helmet>
            <Header className="impressum" slogan="" offersData={offersData}/>
            <main className="container impressum pt">
                <h1>Hinweise zum Datenschutz</h1>
                <ReactMarkdown children={dataProtectionPageData.content}/>
            </main>
        </Layout>
    )
}

export default IndexPage


export const pageQuery = graphql`  
  query PageDatenschutzQuery {
  allStrapiPageDatenschutzs {
    edges {
      node {
        content
        id
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
 }
`

